import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { memo } from 'react';

import Box from 'shopper/components/Box';
import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import Anchor from 'components/Anchor';
import Image from 'components/Image';

import useClientSide from 'hooks/useClientSide';
import useDialog from 'hooks/useDialog';
import useLocalStorageState from 'hooks/useLocalStorageState';
import useMediaQuery from 'hooks/useMediaQuery';

import { sendEvent } from 'lib/gtag';
import { toImageWithPreservedAspectRatioSize } from 'lib/image';

import APP from 'constants/app';

const LoginDialog = dynamic(() => import('components/LoginDialog'), {
  ssr: false,
});

const OfferGridCardWithAd = ({
  description,
  gaEventAction = 'card_banner__click',
  gaEventCategory,
  id,
  image = null,
  isClosable = false,
  mobileImage = null,
  url,
  onClick,
}) => {
  const isClientSide = useClientSide();
  const { isMd } = useMediaQuery();
  const { showDialog } = useDialog();
  const [isEnabledByLocalStorage, setEnabledByLocalStorage] =
    useLocalStorageState(`promobit.timeline.is-card-ad-${id}-enabled`, true);

  const onAdClick = (event) => {
    /**
     * Due SSoT and the impossibility to use hooks inside objects, we take
     * advantage that all injected cardAds have `onClick` to know that when the
     * ad is clicked, we need to open `LoginDialog`.
     */
    if (onClick) {
      event.preventDefault();
      showDialog(LoginDialog);
    }

    sendEvent({
      action: gaEventAction,
      category: gaEventCategory,
    });
  };

  const onCloseClick = () => {
    setEnabledByLocalStorage(false);
  };

  const isAdFromRemoteImagePath = !image.includes(APP.LOCAL_IMAGE_PATH);
  const isDisabled =
    (!mobileImage && !image) ||
    (isClientSide
      ? !isEnabledByLocalStorage || (isMd && !image) || (!isMd && !mobileImage)
      : false);

  if (isDisabled) {
    return null;
  }

  return (
    <Box className="relative block cursor-pointer overflow-hidden rounded-2 md:flex md:justify-between lg:hover:shadow-md lg:hover:transition-all">
      <Anchor
        className="block h-full"
        href={url}
        rel="noreferrer"
        target="_blank"
        onClick={onAdClick}
      >
        <Image
          alt={description}
          className="block w-full object-cover md:hidden"
          height={199}
          sizes="(max-width: 768px) 328px, 1px"
          src={
            isAdFromRemoteImagePath
              ? toImageWithPreservedAspectRatioSize(mobileImage, 328)
              : mobileImage
          }
          srcSet={
            isAdFromRemoteImagePath
              ? `
                ${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w,
                ${toImageWithPreservedAspectRatioSize(mobileImage, 492)} 492w,
                ${toImageWithPreservedAspectRatioSize(mobileImage, 656)} 656w
              `
              : null
          }
          width={328}
        />
        <Image
          alt={description}
          className="hidden size-full object-cover md:block"
          height={407}
          sizes="(max-width: 768px) 1px, 224px"
          src={
            isAdFromRemoteImagePath
              ? toImageWithPreservedAspectRatioSize(image, 224)
              : image
          }
          srcSet={
            isAdFromRemoteImagePath
              ? `
                ${APP.LOCAL_IMAGE_PATH}/general/placeholder.jpg 1w,
                ${toImageWithPreservedAspectRatioSize(image, 336)} 336w,
                ${toImageWithPreservedAspectRatioSize(image, 448)} 448w
              `
              : null
          }
          width={224}
        />
      </Anchor>
      {isClosable && (
        <Button
          className="absolute right-0 top-0 mr-2 mt-2"
          icon={<Icon name="close" />}
          size="size4"
          type="secondary-stroke"
          onClick={onCloseClick}
        />
      )}
    </Box>
  );
};

OfferGridCardWithAd.propTypes = {
  description: PropTypes.string.isRequired,
  // Used only for hardcoded card ad and are relevant for SEO
  gaEventAction: PropTypes.string,
  gaEventCategory: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  isClosable: PropTypes.bool,
  mobileImage: PropTypes.string,
  url: PropTypes.string,
  // Used only for hardcoded card ad
  onClick: PropTypes.func,
};

export default memo(OfferGridCardWithAd);
